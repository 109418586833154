export const OffersEarlyBirdIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.75 14.75C10.125 14.75 9.59375 14.5312 9.15625 14.0938C8.71875 13.6562 8.5 13.125 8.5 12.5C8.5 11.875 8.71875 11.3438 9.15625 10.9062C9.59375 10.4688 10.125 10.25 10.75 10.25C11.375 10.25 11.9062 10.4688 12.3438 10.9062C12.7812 11.3438 13 11.875 13 12.5C13 13.125 12.7812 13.6562 12.3438 14.0938C11.9062 14.5312 11.375 14.75 10.75 14.75ZM10.1875 9.5V8H11.3125V9.5H10.1875ZM10.1875 17V15.5H11.3125V17H10.1875ZM13.2812 10.7563L12.475 9.96875L13.5438 8.9L14.3312 9.70625L13.2812 10.7563ZM7.95625 16.0812L7.16875 15.2938L8.2375 14.225L9.025 15.0125L7.95625 16.0812ZM13.75 13.0625V11.9375H15.25V13.0625H13.75ZM6.25 13.0625V11.9375H7.75V13.0625H6.25ZM13.5438 16.0812L12.4938 15.0125L13.2812 14.225L14.35 15.275L13.5438 16.0812ZM8.21875 10.775L7.16875 9.70625L7.95625 8.91875L9.025 9.96875L8.21875 10.775ZM1.75 15.5C1.3375 15.5 0.984375 15.3531 0.690625 15.0594C0.396875 14.7656 0.25 14.4125 0.25 14V3.5C0.25 3.0875 0.396875 2.73438 0.690625 2.44063C0.984375 2.14688 1.3375 2 1.75 2H2.5V0.5H4V2H10V0.5H11.5V2H12.25C12.6625 2 13.0156 2.14688 13.3094 2.44063C13.6031 2.73438 13.75 3.0875 13.75 3.5V6.5H1.75V14H4.75V15.5H1.75ZM1.75 5H12.25V3.5H1.75V5Z"
        fill="#EB860E"
      />
    </svg>
  );
};
