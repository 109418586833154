import { BodyText } from "@/components/common/texts/body-text";
import { TitleText } from "@/components/common/texts/title-text";
import { formatIndianCurrency } from "../../../../lib/utility";
import { LabelText } from "@/components/common/texts/label-text";
import { ListingPageOffersIcon } from "../../../../assets/listing-page-offers-icon";
import { MobileSoldOutIcon } from "../../../../assets/mobile-sold-out-icon";

export const DisplayPrices = ({
  isPerNight,
  price,
  cutThroughPrice,
  isBottomBar,
}: {
  isPerNight?: boolean;
  price: number;
  cutThroughPrice: number;
  isBottomBar?: boolean;
}) => {
  return (
    <div className="space-x-[5px]">
      {cutThroughPrice ? (
        <BodyText
          className={`text-error line-through !text-[12px] !leading-[14px] inline ${
            isBottomBar ? "mobile:!text-[10px]" : "mobile:!text-[12px]"
          }`}
        >
          ₹ {formatIndianCurrency(cutThroughPrice)}
        </BodyText>
      ) : null}
      <TitleText
        className={`text-primaryB9 !text-[18px] !leading-[20px] inline ${
          isBottomBar ? "mobile:!text-[16px]" : "mobile:!text-[18px]"
        }`}
      >
        ₹ {formatIndianCurrency(price)}
        {isPerNight && (
          <BodyText className="!text-[12px] !leading-[14px] text-neutralN7 inline">
            /night
          </BodyText>
        )}
      </TitleText>
    </div>
  );
};

export const DisplayTotalPrices = ({ isBooking }: { isBooking?: boolean }) => {
  return (
    <div className="flex flex-col gap-y-2">
      <TitleText className="!text-[18px] !leading-[20px] !normal-case text-primaryB9">
        Total Price
      </TitleText>
      {!isBooking ? (
        <LabelText className="!font-light !text-[12px] !leading-[14px] !normal-case text-neutralN7">
          *GST will be added in the next steps
        </LabelText>
      ) : null}
    </div>
  );
};

export const Offer = ({
  isDates,
  isModal,
}: {
  isDates: boolean;
  isModal?: boolean;
}) => {
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        backgroundImage:
          "linear-gradient(0deg, rgba(86, 181, 59, 0.15), rgba(86, 181, 59, 0.15))",
      }}
      className={`z-10 w-full flex items-center gap-x-2.5 rounded-[2.5px] p-[5px] border-[0.5px] border-secondaryG2 mobile:py-2 mobile:pl-2.5 mobile:pr-5 mobile:rounded-none ${
        isModal && "mobile:rounded-t-[5px]"
      }`}
    >
      <ListingPageOffersIcon />
      <BodyText className="text-success !text-[12px] !leading-[14px] !normal-case">
        {isDates
          ? "Book Now & Unlock Exclusive Offers!"
          : "Select Dates for Best Prices and Offers!"}
      </BodyText>
    </div>
  );
};

export const FullyBooked = ({ isModal }: { isModal: boolean }) => {
  return (
    <div
      className={`py-[5px] px-2.5 mobile:pr-5 mobile:py-2 rounded-[2.5px] mobile:rounded-none bg-error ${
        isModal && "rounded-t-[5px]"
      }`}
    >
      <div className="flex items-center gap-x-[5px]">
        <MobileSoldOutIcon />
        <BodyText className="text-white !text-[12px] !leading-[14px]">
          fully booked! Choose different dates to secure your stay!
        </BodyText>
      </div>
    </div>
  );
};

export const ExploreNow = ({ exploreNow }: { exploreNow: () => void }) => {
  return (
    <BodyText className="!text-[12px] !leading-[14px] text-neutralN7 !normal-case">
      We&apos;ve got more stays available for your dates!{" "}
      <button className="text-neutralN8 underline" onClick={exploreNow}>
        Explore now
      </button>
    </BodyText>
  );
};

export const CostBreakDown = ({
  title,
  breakdown,
  totalCost,
  isDiscount,
}: {
  title: string;
  breakdown: string;
  totalCost: string;
  isDiscount: boolean;
}) => {
  return (
    <div className="w-full flex flex-col gap-y-2">
      <BodyText className="!text-[14px] !leading-[14px] !normal-case !text-neutralN8">
        {title}:
      </BodyText>
      <div className="w-full flex items-end justify-between">
        <TitleText className="!text-[18px] !leading-[18px] text-neutralN9 !normal-case">
          {breakdown}
        </TitleText>
        <TitleText
          className={`!text-[18px] !leading-[18px] text-neutralN9 ${
            isDiscount ? "text-success" : "text-neutralN9"
          }`}
        >
          {isDiscount && "-"}
          {totalCost}
        </TitleText>
      </div>
    </div>
  );
};
