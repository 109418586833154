import { create } from "zustand";

export type CouponType = {
  code: string;
  type: string;
  from: Date;
  to: Date;
  description: string;
};

export interface DiscountedCoupon {
  code: string;
  discount: number;
}

interface UpdatePropertyCouponsState {
  validPropertyCoupons: CouponType[];
  updateValidPropertyCoupons: (validPropertyCoupons: CouponType[]) => void;
  appliedCoupons: string[];
  updateAppliedCoupons: (appliedCoupons: string[]) => void;
  discountedCoupons: DiscountedCoupon[];
  updateDiscountedCoupons: (discountedCoupons: DiscountedCoupon[]) => void;
  referralCode: DiscountedCoupon | null;
  updateReferralCode: (referralCode: DiscountedCoupon | null) => void;
}

export const useUpdatePropertyCoupons = create<UpdatePropertyCouponsState>(
  (set) => {
    return {
      validPropertyCoupons: [],
      updateValidPropertyCoupons: (validPropertyCoupons) =>
        set(() => ({
          validPropertyCoupons,
        })),
      appliedCoupons: [],
      updateAppliedCoupons: (appliedCoupons) =>
        set(() => ({
          appliedCoupons,
        })),
      discountedCoupons: [],
      updateDiscountedCoupons: (discountedCoupons) =>
        set(() => ({
          discountedCoupons,
        })),
      referralCode: null,
      updateReferralCode: (referralCode) =>
        set(() => ({
          referralCode,
        })),
    };
  }
);
