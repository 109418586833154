import { COUPON_TYPES } from "@/types/common";
import { db } from "../lib/connect-firebase"; // Path to your firebase config file
import { collection, query, where, getDocs } from "firebase/firestore";

export class CouponsDataV2FireBase {
  private couponsV2 = collection(db, "couponsV2");

  async getBasicCoupon() {
    try {
      // Create a query against the collection for basic, active coupons
      const q = query(
        this.couponsV2,
        where("type", "==", COUPON_TYPES.BASIC),
        where("isActive", "==", true)
      );

      // Execute the query
      const querySnapshot = await getDocs(q);

      // Return the first matching document if it exists
      if (!querySnapshot.empty) {
        const couponDoc = querySnapshot.docs[0];
        return {
          ...couponDoc.data(),
        };
      }

      // Return null if no matching coupons found
      return null;
    } catch (error) {
      console.error(`Error getting basic coupon: ${error}`);
      return null;
    }
  }

  async getAllCoupons() {
    try {
      const q = query(this.couponsV2, where("isActive", "==", true));

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        return querySnapshot.docs
          .filter((doc) => doc.data().type !== COUPON_TYPES.PRIVATE)
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
      }

      return [];
    } catch (error) {
      console.error(`Error getting coupons: ${error}`);
      return [];
    }
  }
}
