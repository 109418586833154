"use client";

import { FestiveOffers } from "./festive-offers";
import { HeadlineOffer } from "./headline-offer";
import { Referral } from "./referral";
import { StayOffers } from "./stay-offers";

export const Offers = ({
  isProperty,
  isBooking,
  isListingPage,
  isMobileListingPage,
}: {
  isProperty: boolean;
  isBooking?: boolean;
  isListingPage?: boolean;
  isMobileListingPage?: boolean;
}) => {
  return (
    <main
      className={`${isProperty ? "" : "py-[50px] px-[75px]"} ${
        isBooking ? "mt-[100px] mobile:!px-0 mobile:mt-[60px]" : ""
      } ${isListingPage ? "bg-neutralN2" : ""} mobile:px-5 mobile:pt-[25px]`}
    >
      <div className="w-full flex flex-col gap-y-[50px] mobile:gap-y-[30px]">
        <HeadlineOffer
          isMobileListingPage={isMobileListingPage}
          isListingsPage={isListingPage}
        />
        <Referral isListingPage={isListingPage} />
        <FestiveOffers isListingPage={isListingPage} isProperty={isProperty} />
        <StayOffers isListingPage={isListingPage} isProperty={isProperty} />
      </div>
    </main>
  );
};
