import { BodyText } from "@/components/common/texts/body-text";
import { MobileOffersIcon } from "../../../../assets/mobile-offers-icon";
import { OffersIcon } from "../../../../assets/offers-icon";
import { useEffect, useState } from "react";
import Link from "next/link";
import { isLoggedIn } from "../../../../actions/auth";

export const HeadlineOffer = ({
  isListingsPage,
  isMobileListingPage,
}: {
  isListingsPage?: boolean;
  isMobileListingPage?: boolean;
}) => {
  const [isUser, setIsUser] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await isLoggedIn();
      if (data) setIsUser(true);
      else setIsUser(false);
    })();
  });

  return (
    <Link
      href={isUser ? "/profile" : "login"}
      className={`${
        isListingsPage || isMobileListingPage ? "hidden" : "w-[55%]"
      } flex items-center gap-x-2 py-2 pl-2.5 pr-4 bg-[#F2F9FB] border border-[#9FDFF0] mobile:w-full mobile:gap-x-3`}
    >
      <div className="mobile:hidden">
        <OffersIcon />
      </div>
      <div className="hidden mobile:block">
        <MobileOffersIcon />
      </div>
      <BodyText className="text-neutralN8 !normal-case">
        You can now apply two Spacez Offers along with the Festive Offer and
        Referral Discount!
      </BodyText>
    </Link>
  );
};
