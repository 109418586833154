export const OffersLongStayIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3843_655"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3843_655)">
        <path
          d="M1.66602 16.6663V8.33301L6.66602 3.33301L11.666 8.33301V16.6663H1.66602ZM3.33268 14.9997H5.83268V12.4997H7.49935V14.9997H9.99935V9.02051L6.66602 5.68717L3.33268 9.02051V14.9997ZM5.83268 10.833V9.16634H7.49935V10.833H5.83268ZM13.3327 16.6663V7.64551L9.02018 3.33301H11.3743L14.9993 6.95801V16.6663H13.3327ZM16.666 16.6663V6.27051L13.7285 3.33301H16.0827L18.3327 5.58301V16.6663H16.666Z"
          fill="#6C5844"
        />
      </g>
    </svg>
  );
};
