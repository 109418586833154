import { BodyText } from "@/components/common/texts/body-text";
import { HeadlineText } from "@/components/common/texts/headline-text";
import { LabelText } from "@/components/common/texts/label-text";
import { TitleText } from "@/components/common/texts/title-text";
import React, { Dispatch, SetStateAction } from "react";
import * as gtag from "./../../../../lib/gtag";

interface OfferCardProps {
  className?: string;
  children: React.ReactNode;
}

export const OfferCard = ({ children, className }: OfferCardProps) => {
  return (
    <div
      className={`${className} py-5 px-6 flex flex-col gap-y-5 rounded-md border-t-[0.5px] border-r-[0.5px] border-b-[0.5px] border-l-8 border-primaryB4 bg-neutralN2 mobile:px-5`}
    >
      {children}
    </div>
  );
};

interface TitleProps {
  text: string;
}

export const OfferTitle = ({ text }: TitleProps) => {
  return (
    <HeadlineText className="!font-extrabold !text-[32px] !leading-[34px] text-primaryB7 !normal-case mobile:!text-[24px] mobile:!leading-[28px]">
      {text}
    </HeadlineText>
  );
};

interface OfferChildrenContainerProps {
  children: React.ReactNode;
  descriptionText: string;
  isPropertyId: boolean;
  isActive: boolean;
  code: string;
  handleClick: (code: string) => void;
  isApplied: boolean;
}

export const OfferChildrenContainer = ({
  children,
  descriptionText,
  isPropertyId,
  isActive,
  code,
  handleClick,
  isApplied,
}: OfferChildrenContainerProps) => {
  const handleButtonClick = () => {
    gtag.event({
      action: `${code.toLowerCase()}_coupon_${
        isPropertyId ? (isApplied ? "remove" : "apply") : "copy"
      }`,
      category: "click",
      label: "User clicked the offers available",
      value: 1,
    });
    handleClick(code);
  };

  return (
    <div className="w-full flex flex-col gap-y-5">
      <div className="flex items-center justify-between">
        {children}
        <button onClick={handleButtonClick} disabled={!isActive}>
          <TitleText className="text-primaryB4 !text-[18px] !leading-[20px] !uppercase">
            {isPropertyId ? (isApplied ? "REMOVE" : "APPLY") : "COPY"}
          </TitleText>
        </button>
      </div>
      <LabelText className="text-neutralN8 !normal-case">
        {descriptionText}
      </LabelText>
    </div>
  );
};

export const ReadMoreText = ({
  tAndC,
  isReadMoreOpen,
  setIsReadMoreOpen,
  index,
}: {
  tAndC: string[];
  isReadMoreOpen: { index: number; value: boolean };
  setIsReadMoreOpen: Dispatch<
    SetStateAction<{ index: number; value: boolean }>
  >;
  index: number;
}) => {
  const isOpen = isReadMoreOpen.value && index === isReadMoreOpen.index;
  return (
    <div className="pt-[18px] border-t-[0.5px] border-neutralN6">
      <div className="flex flex-col gap-y-5">
        {isOpen && (
          <div className="flex flex-col gap-y-5">
            <BodyText className="!normal-case !text-[12px] !leading-[14px] text-neutralN8">
              Terms and Conditions applied:
            </BodyText>
            {tAndC.map((item) => (
              <LabelText
                className="!normal-case text-neutralN8 !font-light !text-[12px] !leading-[14px]"
                key={item}
              >
                {item}
              </LabelText>
            ))}
            <div className="border-t-[0.5px] border-neutralN6"></div>
          </div>
        )}
        <button
          onClick={() =>
            setIsReadMoreOpen((prev) => ({ index, value: !prev.value }))
          }
        >
          <BodyText className="!text-left !text-[14px] !leading-[14px] text-neutralN8">
            {isOpen ? "Read Less >" : "Read More >"}
          </BodyText>
        </button>
      </div>
    </div>
  );
};
