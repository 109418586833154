export const OffersRushDealIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3843_6882"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3843_6882)">
        <path
          d="M12.5007 16.6663C10.6395 16.6663 9.06315 16.0205 7.77148 14.7288C6.47982 13.4372 5.83398 11.8608 5.83398 9.99967C5.83398 8.15245 6.47982 6.57954 7.77148 5.28092C9.06315 3.98231 10.6395 3.33301 12.5007 3.33301C14.3479 3.33301 15.9208 3.98231 17.2194 5.28092C18.518 6.57954 19.1673 8.15245 19.1673 9.99967C19.1673 11.8608 18.518 13.4372 17.2194 14.7288C15.9208 16.0205 14.3479 16.6663 12.5007 16.6663ZM12.5007 14.9997C13.8895 14.9997 15.0701 14.5136 16.0423 13.5413C17.0145 12.5691 17.5006 11.3886 17.5006 9.99967C17.5006 8.61079 17.0145 7.43023 16.0423 6.45801C15.0701 5.48579 13.8895 4.99967 12.5007 4.99967C11.1118 4.99967 9.93121 5.48579 8.95898 6.45801C7.98676 7.43023 7.50065 8.61079 7.50065 9.99967C7.50065 11.3886 7.98676 12.5691 8.95898 13.5413C9.93121 14.5136 11.1118 14.9997 12.5007 14.9997ZM14.3965 13.1038L15.584 11.9163L13.334 9.66634V6.66634H11.6673V10.3538L14.3965 13.1038ZM1.66732 7.49967V5.83301H5.00065V7.49967H1.66732ZM0.833984 10.833V9.16634H5.00065V10.833H0.833984ZM1.66732 14.1663V12.4997H5.00065V14.1663H1.66732Z"
          fill="#DF3838"
        />
      </g>
    </svg>
  );
};
