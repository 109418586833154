import { Lexend_Deca } from "next/font/google";
import {
  OfferCard,
  OfferChildrenContainer,
  OfferTitle,
  ReadMoreText,
} from "./common";
import { LabelText } from "@/components/common/texts/label-text";
import { HoliIcon } from "../../../../assets/holi-icon";
import { CouponsDataV2FireBase } from "../../../../data/coupons_data";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { handleCoupon, isCouponActive, isCouponApplied } from "@/lib/coupons";
import { useUpdatePropertyCoupons } from "@/store/update-property-coupons";

export const lexendDeca = Lexend_Deca({ subsets: ["latin"], weight: "700" });

interface Props {
  isProperty: boolean;
  isListingPage?: boolean;
}

export const FestiveOffers = ({ isProperty, isListingPage }: Props) => {
  const { appliedCoupons, updateAppliedCoupons, validPropertyCoupons } =
    useUpdatePropertyCoupons((state) => state);
  const [isReadMoreOpen, setIsReadMoreOpen] = useState({
    index: 0,
    value: false,
  });
  const [coupon, setCoupon] = useState<any | null>([]);

  const handleClick = (couponName: string) => {
    handleCoupon({
      couponName,
      isProperty,
      appliedCoupons,
      updateAppliedCoupons,
    });
  };

  useEffect(() => {
    (async () => {
      const couponDataService = new CouponsDataV2FireBase();
      const data = await couponDataService.getBasicCoupon();
      if (!data || data.length === 0) {
        setCoupon(null);
        return null;
      }
      setCoupon(data);
    })();
  }, []);

  if (
    !coupon ||
    coupon.length === 0 ||
    !isCouponActive({
      coupons: validPropertyCoupons,
      couponName: coupon.code,
    })
  )
    return null;

  return (
    <div className="w-full flex flex-col gap-y-[15px]">
      <OfferTitle text="Festive Offers" />
      <OfferCard
        className={`${
          isListingPage ? "w-full bg-white" : "!w-1/3"
        } mobile:!w-full`}
      >
        <OfferChildrenContainer
          isActive={isCouponActive({
            coupons: validPropertyCoupons,
            couponName: coupon.code,
          })}
          isPropertyId={isProperty}
          descriptionText={coupon.description}
          isApplied={isCouponApplied({
            couponName: coupon.code,
            coupons: appliedCoupons,
          })}
          code={coupon.code}
          handleClick={handleClick}
        >
          <div className="flex items-center gap-x-2">
            <HoliIcon />
            <h1
              style={{
                backgroundImage:
                  "linear-gradient(90deg, #EC5613 2.99%, #D32CB5 26.51%, #45BA93 50.03%, #FDA502 73.55%, #CF305C 97.07%)",
              }}
              className={`${lexendDeca.className} !text-[18px] !leading-[20px] bg-clip-text text-transparent`}
            >
              {coupon.code}
            </h1>
          </div>
        </OfferChildrenContainer>
        <div className="border-[0.5px] border-dashed border-neutralN6 p-2 rounded-[2.5px] bg-white">
          <LabelText className="text-neutralN8 !leading-[14px] !normal-case">
            {`Offer valid till: ${format(
              coupon.applicableTo,
              "dd MMMM, yyyy"
            )}`}
          </LabelText>
        </div>
        {coupon.termsAndCondition && (
          <ReadMoreText
            index={0}
            isReadMoreOpen={isReadMoreOpen}
            setIsReadMoreOpen={setIsReadMoreOpen}
            tAndC={coupon.termsAndCondition}
          />
        )}
      </OfferCard>
    </div>
  );
};
