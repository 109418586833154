import { CouponType, DiscountedCoupon } from "@/store/update-property-coupons";
import toast from "react-hot-toast";
import { dateDifference } from "./search-data";
import { getRoomPrice } from "./utils";
import { AllPricesType, PriceType } from "@/store/update-prices";
import { SelectedRoom } from "@/types/room_type";

export const isCouponActive = ({
  coupons,
  couponName,
}: {
  coupons: CouponType[];
  couponName: string;
}): boolean => {
  const couponExists = coupons.some((coupon) => coupon.code === couponName);

  return couponExists;
};

export const isCouponApplied = ({
  coupons,
  couponName,
}: {
  coupons: string[];
  couponName: string;
}): boolean => {
  const couponExists = coupons.some((coupon) => coupon === couponName);

  return couponExists;
};

export const handleCoupon = ({
  couponName,
  isProperty,
  appliedCoupons,
  updateAppliedCoupons,
}: {
  couponName: string;
  appliedCoupons: string[];
  isProperty: boolean;
  updateAppliedCoupons: (appliedCoupons: string[]) => void;
}) => {
  if (isProperty) {
    const isApplied = isCouponApplied({
      couponName,
      coupons: appliedCoupons,
    });
    if (isApplied) {
      const coupons = appliedCoupons.filter((item) => item !== couponName);
      updateAppliedCoupons(coupons);
      localStorage.setItem("appliedCoupons", JSON.stringify(coupons));
      toast.success(`${couponName} removed successfully`);
    } else {
      const coupons = [...appliedCoupons, couponName];
      updateAppliedCoupons(coupons);
      localStorage.setItem("appliedCoupons", JSON.stringify(coupons));
      toast.success(`${couponName} applied successfully`);
    }
  } else {
    navigator.clipboard
      .writeText(couponName)
      .then(() => {
        toast.success("Coupon code copied to clipboard!");
      })
      .catch(() => {
        toast.error("Failed to copy coupon code");
      });
  }
};

interface Props {
  checkIn: Date;
  checkOut: Date;
  noOfGuests: number;
  ogRoomPrice?: number;
  allPrices: AllPricesType;
  selectedRooms?: SelectedRoom[] | null;
  defaultPropertyPrice: number;
  isFullVilla?: boolean;
  updateVillaPricePerNight: (villaPricePerNight: number) => void;
  updateTotalDiscount: (totalDiscount: number) => void;
  updatePrice: (price: PriceType) => void;
  updateCutThroughPrice: (cutThroughPrice: number) => void;
  updateCutThroughPricePerNight: (cutThroughPricePerNight: number) => void;
  updateDiscountedCoupons: (discountedCoupons: DiscountedCoupon[]) => void;
}

export const updateCouponPrices = ({
  checkIn,
  checkOut,
  noOfGuests,
  allPrices,
  selectedRooms,
  defaultPropertyPrice,
  isFullVilla,
  updateCutThroughPrice,
  updatePrice,
  updateCutThroughPricePerNight,
  updateTotalDiscount,
  updateVillaPricePerNight,
  updateDiscountedCoupons,
}: Props) => {
  if (allPrices) {
    const dateDiff = dateDifference(checkIn, checkOut);
    const price = allPrices[noOfGuests];
    if (!price) return;
    const totalDiscount = +(price.total_price - price.discounted_price).toFixed(
      0
    );
    const pricePerNight = +(price.discounted_price / dateDiff).toFixed(0);
    updateVillaPricePerNight(pricePerNight);

    updateTotalDiscount(totalDiscount);
    updatePrice({ finalPrice: price.discounted_price, pricePerNight });
    updateDiscountedCoupons(price.applied_coupons)
    if (price.total_price > price.discounted_price) {
      updateCutThroughPrice(+price.total_price.toFixed(0));
      updateCutThroughPricePerNight(+(price.total_price / dateDiff).toFixed(0));
    } else {
      updateCutThroughPrice(0);
      updateCutThroughPricePerNight(0);
    }

    if (!isFullVilla) {
      let totalPrice = 0;
      selectedRooms?.forEach((item) => {
        totalPrice += getRoomPrice({
          ogRoomPrice: +item.price,
          ogVillaPrice: +defaultPropertyPrice,
          newVillaPrice: pricePerNight,
        });
      });
      const finalPrice = totalPrice * dateDiff;
      updateTotalDiscount(0);
      updatePrice({ finalPrice, pricePerNight: totalPrice });
      updateCutThroughPrice(0);
      updateCutThroughPricePerNight(0);
    }
  }
};

export const isEarlyBird = ({ checkInDate }: { checkInDate: Date }) => {
  // Check if checkInDate is at least 28 days in advance
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time portion for accurate day comparison

  // Calculate difference in milliseconds and convert to days
  const differenceInTime = checkInDate.getTime() - today.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays >= 28;
};

export const isLongStay = ({
  checkInDate,
  checkOutDate,
}: {
  checkInDate: Date;
  checkOutDate: Date;
}) => {
  // Check if booking is at least for 5 days or more
  const differenceInTime = checkOutDate.getTime() - checkInDate.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays >= 5;
};

export const isRushDeal = ({ checkInDate }: { checkInDate: Date }) => {
  // Check if checkInDate is today or tomorrow
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time portion for accurate day comparison

  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  // Reset time portion of checkInDate for accurate comparison
  const checkInWithoutTime = new Date(checkInDate);
  checkInWithoutTime.setHours(0, 0, 0, 0);

  return (
    checkInWithoutTime.getTime() === today.getTime() ||
    checkInWithoutTime.getTime() === tomorrow.getTime()
  );
};
