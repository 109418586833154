import { TitleText } from "@/components/common/texts/title-text";
import {
  OfferCard,
  OfferChildrenContainer,
  OfferTitle,
  ReadMoreText,
} from "./common";
import { OffersEarlyBirdIcon } from "../../../../assets/offers-early-bird-icon";
import { OffersLongStayIcon } from "../../../../assets/offers-long-stay-icon";
import { useEffect, useState } from "react";
import { CouponsDataV2FireBase } from "../../../../data/coupons_data";
import { LabelText } from "../texts/label-text";
import { format } from "date-fns";
import { useUpdatePropertyCoupons } from "@/store/update-property-coupons";
import { handleCoupon, isCouponActive, isCouponApplied } from "@/lib/coupons";
import { OffersRushDealIcon } from "../../../../assets/offers-rush-deal";
import { COUPON_TYPES } from "@/types/common";

export const getCouponCodeIcon = (couponType: COUPON_TYPES) => {
  switch (couponType) {
    case COUPON_TYPES.EARLY_BIRD:
      return <OffersEarlyBirdIcon />;
    case COUPON_TYPES.LONG_STAY:
      return <OffersLongStayIcon />;
    case COUPON_TYPES.LAST_MINUTE:
      return <OffersRushDealIcon />;
    default:
      return null;
  }
};

export const getCouponColorClass = (couponType: COUPON_TYPES) => {
  switch (couponType) {
    case COUPON_TYPES.EARLY_BIRD:
      return "text-[#EB860E]";
    case COUPON_TYPES.LONG_STAY:
      return "text-[#6C5844]";
    case COUPON_TYPES.LAST_MINUTE:
      return "text-[#DF3838]";
    default:
      return "text-[#EB860E]";
  }
};

interface Props {
  isProperty: boolean;
  isListingPage?: boolean;
}

export const StayOffers = ({ isProperty, isListingPage }: Props) => {
  const { validPropertyCoupons, appliedCoupons, updateAppliedCoupons } =
    useUpdatePropertyCoupons((state) => state);
  const [coupons, setCoupons] = useState<any[] | null>(null);
  const [isReadMoreOpen, setIsReadMoreOpen] = useState({
    index: 0,
    value: false,
  });

  const handleClick = (couponName: string) => {
    handleCoupon({
      couponName,
      isProperty,
      appliedCoupons,
      updateAppliedCoupons,
    });
  };

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const couponDataService = new CouponsDataV2FireBase();
        const data = await couponDataService.getAllCoupons();

        if (!data || data.length === 0) {
          setCoupons(null);
          return;
        }

        setCoupons(data);
      } catch (error) {
        console.error("Error fetching coupons:", error);
        setCoupons(null);
      }
    };

    fetchCoupons();
  }, []);

  // Early return if coupons are still loading or no coupons found
  if (coupons === null) {
    return null;
  }

  return (
    <div className="w-full flex flex-col gap-y-[15px]">
      <OfferTitle text="Stay Offers" />
      {coupons.length > 0 && (
        <div
          className={`w-full grid gap-2.5 mobile:grid-cols-1 ${
            isListingPage ? "grid-cols-1" : "grid-cols-3 "
          }`}
        >
          {coupons
            .filter((item) => item.type !== COUPON_TYPES.BASIC)
            .map((item, index) => {
              const isActive = isProperty
                ? isCouponActive({
                    couponName: item.code,
                    coupons: validPropertyCoupons,
                  })
                : true;

              const isApplied = isCouponApplied({
                couponName: item.code,
                coupons: appliedCoupons,
              });

              const colorClass = getCouponColorClass(item.type);

              return (
                <div key={index} style={{ alignSelf: "flex-start" }}>
                  <OfferCard
                    className={`${!isActive && "!grayscale"} ${isListingPage ? "bg-white": ""} flex flex-col h-auto w-full`}
                  >
                    <OfferChildrenContainer
                      isActive={isActive}
                      isPropertyId={isProperty}
                      descriptionText={item.description}
                      code={item.code}
                      handleClick={handleClick}
                      isApplied={isApplied}
                    >
                      <div className="flex items-center gap-x-2">
                        {getCouponCodeIcon(item.type)}
                        <TitleText
                          className={`${colorClass} !uppercase !text-[18px] !leading-[20px]`}
                        >
                          {item.code}
                        </TitleText>
                      </div>
                    </OfferChildrenContainer>

                    {item.applicableTo && (
                      <div className="border-[0.5px] border-dashed border-neutralN6 p-2 rounded-[2.5px] bg-white">
                        <LabelText className="text-neutralN8 !leading-[14px] !normal-case">
                          {`Offer valid till: ${format(
                            item.applicableTo,
                            "dd MMMM, yyyy"
                          )}`}
                        </LabelText>
                      </div>
                    )}

                    <ReadMoreText
                      index={index}
                      isReadMoreOpen={isReadMoreOpen}
                      setIsReadMoreOpen={setIsReadMoreOpen}
                      tAndC={item.termsAndCondition}
                    />
                  </OfferCard>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
