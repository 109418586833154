import { TitleText } from "@/components/common/texts/title-text";
import { BodyText } from "@/components/common/texts/body-text";
import { ReferralGiftIcon } from "../../../../assets/referral-gift-icon";

export const Referral = ({ isListingPage }: { isListingPage?: boolean }) => {
  return (
    <div
      className={`py-5 px-6 flex flex-col gap-y-5 rounded-md border-t-[0.5px] border-r-[0.5px] border-b-[0.5px] border-l-8 border-primaryB7 ${
        isListingPage ? "bg-white" : "bg-neutralN2"
      } mobile:px-5`}
    >
      <div className="flex items-center gap-x-2">
        <ReferralGiftIcon />
        <TitleText className="text-neutralN9 !text-[18px] !leading-[20px] !normal-case mobile:!text-[16px] mobile:!leading-[18px]">
          Got a Referral? Unlock ₹500 OFF!
        </TitleText>
      </div>
      <BodyText className="text-neutralN8 !text-[14px] !leading-[16px] !normal-case mobile:!text-[12px] mobile:!leading-[14px]">
        Someone sent you our way? Enter the referral code and grab a flat ₹500
        discount on your booking!
      </BodyText>
    </div>
  );
};
