export const HoliIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3843_8397"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3843_8397)">
        <path
          d="M1.66602 18.3333L5.83268 6.66667L13.3327 14.1667L1.66602 18.3333ZM4.41602 15.5833L10.291 13.5L6.49935 9.70833L4.41602 15.5833ZM12.1243 10.4583L11.2493 9.58333L15.916 4.91667C16.3605 4.47222 16.8952 4.25 17.5202 4.25C18.1452 4.25 18.6799 4.47222 19.1244 4.91667L19.6243 5.41667L18.7493 6.29167L18.2493 5.79167C18.0549 5.59722 17.8118 5.5 17.5202 5.5C17.2285 5.5 16.9855 5.59722 16.791 5.79167L12.1243 10.4583ZM8.79102 7.125L7.91602 6.25L8.41602 5.75C8.61046 5.55556 8.70768 5.31944 8.70768 5.04167C8.70768 4.76389 8.61046 4.52778 8.41602 4.33333L7.87435 3.79167L8.74935 2.91667L9.29102 3.45833C9.73546 3.90278 9.95768 4.43056 9.95768 5.04167C9.95768 5.65278 9.73546 6.18056 9.29102 6.625L8.79102 7.125ZM10.4577 8.79167L9.58268 7.91667L12.5827 4.91667C12.7771 4.72222 12.8743 4.47917 12.8743 4.1875C12.8743 3.89583 12.7771 3.65278 12.5827 3.45833L11.2493 2.125L12.1243 1.25L13.4577 2.58333C13.9021 3.02778 14.1244 3.5625 14.1244 4.1875C14.1244 4.8125 13.9021 5.34722 13.4577 5.79167L10.4577 8.79167ZM13.791 12.125L12.916 11.25L14.2493 9.91667C14.6938 9.47222 15.2285 9.25 15.8535 9.25C16.4785 9.25 17.0132 9.47222 17.4577 9.91667L18.791 11.25L17.916 12.125L16.5827 10.7917C16.3882 10.5972 16.1452 10.5 15.8535 10.5C15.5618 10.5 15.3188 10.5972 15.1243 10.7917L13.791 12.125Z"
          fill="url(#paint0_linear_3843_8397)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3843_8397"
          x1="2.20362"
          y1="9.6274"
          x2="19.0982"
          y2="9.6274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC5613" />
          <stop offset="0.25" stopColor="#D32CB5" />
          <stop offset="0.5" stopColor="#45BA93" />
          <stop offset="0.75" stopColor="#FDA502" />
          <stop offset="1" stopColor="#CF305C" />
        </linearGradient>
      </defs>
    </svg>
  );
};
