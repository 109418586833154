import { getPropertyPrices } from "@/lib/prices-data";
import {
  addSearchDataInLocalStorage,
  today,
  tomorrow,
} from "@/lib/search-data";
import { useUpdatePrices } from "@/store/update-prices";
import { useUpdatePropertyCoupons } from "@/store/update-property-coupons";
import { COUPON_TYPES, SearchDataType } from "@/types/common";
import { format } from "date-fns";
import { useEffect } from "react";

interface Props {
  searchData: SearchDataType;
  propertyId: string;
  id: string;
  phoneNumber?: string;
  isBookingPage?: boolean;
}

export const useUpdateCouponsData = ({
  searchData,
  id,
  propertyId,
  phoneNumber,
  isBookingPage,
}: Props) => {
  const {
    appliedCoupons,
    updateAppliedCoupons,
    updateValidPropertyCoupons,
    updateDiscountedCoupons,
    updateReferralCode,
  } = useUpdatePropertyCoupons((state) => state);
  const { updateAllPrices } = useUpdatePrices((state) => state);

  useEffect(() => {
    const { checkInDate, checkOutDate, guests } = searchData;
    if (!checkInDate || !checkOutDate || !guests.noOfTotalGuests) return;
    addSearchDataInLocalStorage({
      ...searchData,
      checkInDate,
      checkOutDate,
      guests,
    });

    const checkIn = format(checkInDate, "yyyy-MM-dd");
    const checkOut = format(checkOutDate, "yyyy-MM-dd");
    const body = JSON.stringify({
      checkIn,
      checkOut,
      noOfGuests: guests.noOfTotalGuests,
      propertyId: id,
      phoneNumber: phoneNumber ? phoneNumber : "",
    });

    (async () => {
      try {
        const res = await fetch(
          `${process.env.NEXT_PUBLIC_SAVINGZ_GPT_SERVER}/get-available-coupons-for-property`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body,
          }
        );
        const data = await res.json();

        if (data && Array.isArray(data) && data.length > 0) {
          const activeCoupons = data.filter((coupon) => coupon.isActive);

          const storedAppliedCouponsStr =
            localStorage.getItem("appliedCoupons");
          const storedAppliedCoupons = storedAppliedCouponsStr
            ? JSON.parse(storedAppliedCouponsStr)
            : [];

          let updatedAppliedCoupons = [];

          if (isBookingPage) {
            const referralData = localStorage.getItem("referralCode");
            if (referralData) {
              updateReferralCode(JSON.parse(referralData));
            }

            updatedAppliedCoupons = Array.from(
              new Set([...storedAppliedCoupons])
            ).filter((code) => typeof code === "string");
          } else {
            const specialCouponCodes = activeCoupons
              .filter(
                (coupon) =>
                  coupon.type === COUPON_TYPES.LAST_MINUTE ||
                  coupon.type === COUPON_TYPES.EARLY_BIRD
              )
              .map((coupon) => coupon.code);

            // Combine and deduplicate
            updatedAppliedCoupons = Array.from(
              new Set([
                ...(storedAppliedCoupons || []).filter(Boolean),
                ...specialCouponCodes,
              ])
            ).filter((code) => typeof code === "string");
          }

          // Update state and local storage
          updateAppliedCoupons(updatedAppliedCoupons);
          localStorage.setItem(
            "appliedCoupons",
            JSON.stringify(updatedAppliedCoupons)
          );

          // Process coupons for display
          const processedCoupons = activeCoupons.map((coupon) => ({
            code: coupon.code,
            type: coupon.type,
            description: coupon.description,
            from: new Date(coupon.applicableFrom),
            to: new Date(coupon.applicableTo),
          }));

          updateValidPropertyCoupons(processedCoupons);
        }
      } catch (error) {
        console.error("Error fetching coupons:", error);
      }
    })();
  }, [
    searchData,
    propertyId,
    updateValidPropertyCoupons,
    updateAppliedCoupons,
    id,
    phoneNumber,
  ]);

  useEffect(() => {
    const noOfGuests = searchData.guests.noOfTotalGuests
      ? searchData.guests.noOfTotalGuests
      : 1;

    (async () => {
      const checkInDate =
        searchData.checkInDate && searchData.checkOutDate
          ? searchData.checkInDate
          : today;
      const checkOutDate =
        searchData.checkInDate && searchData.checkOutDate
          ? searchData.checkOutDate
          : tomorrow;
      const checkIn = format(checkInDate, "yyyy-MM-dd");
      const checkOut = format(checkOutDate, "yyyy-MM-dd");
      let referral =  (JSON.parse(localStorage.getItem("referralCode")??"{}"))["code"];
      const prices = await getPropertyPrices({
        checkIn,
        checkOut,
        propertyId: id,
        appliedCoupons: appliedCoupons.length === 0 ? [""] : appliedCoupons,
        referralCode: phoneNumber == null ? undefined : referral?? undefined,
        phoneNumber: phoneNumber ?? undefined
      });
      updateAllPrices(prices);
      updateDiscountedCoupons(prices[noOfGuests].applied_coupons??[""]);
    })();
  }, [
    appliedCoupons,
    searchData.checkInDate,
    searchData.checkOutDate,
    id,
    updateAllPrices,
  ]);
};
