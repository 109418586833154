import { create } from "zustand";
import { DiscountedCoupon } from "./update-property-coupons";

export type PriceType = {
  finalPrice: number;
  pricePerNight: number;
};

export type AllPricesType = Record<
  number,
  {
    total_price: number;
    discounted_price: number;
    applied_coupons: DiscountedCoupon[];
    referalDiscountAmount?: number;
  }
>;

interface UpdatePricesState {
  price: PriceType;
  updatePrice: (price: PriceType) => void;
  totalDiscount: number;
  updateTotalDiscount: (totalDiscount: number) => void;
  allPrices: AllPricesType | null;
  updateAllPrices: (allPrices: AllPricesType) => void;
  cutThroughPrice: number;
  updateCutThroughPrice: (cutThroughPrice: number) => void;
  cutThroughPricePerNight: number;
  updateCutThroughPricePerNight: (cutThroughPricePerNight: number) => void;
  defaultPropertyPrice: number;
  updateDefaultPropertyPrice: (defaultPropertyPrice: number) => void;
  villaPricePerNight: number;
  updateVillaPricePerNight: (villaPricePerNight: number) => void;
}

export const useUpdatePrices = create<UpdatePricesState>((set) => {
  return {
    price: { finalPrice: 0, pricePerNight: 0 },
    totalDiscount: 0,
    allPrices: null,
    cutThroughPrice: 0,
    defaultPropertyPrice: 0,
    cutThroughPricePerNight: 0,
    villaPricePerNight: 0,
    beforeDiscountPrice: 0,
    updatePrice: (price) =>
      set(() => ({
        price,
      })),
    updateTotalDiscount: (totalDiscount) =>
      set(() => ({
        totalDiscount,
      })),
    updateAllPrices: (allPrices) =>
      set(() => ({
        allPrices,
      })),
    updateCutThroughPrice: (cutThroughPrice) =>
      set(() => ({
        cutThroughPrice,
      })),
    updateCutThroughPricePerNight: (cutThroughPricePerNight) =>
      set(() => ({
        cutThroughPricePerNight,
      })),
    updateDefaultPropertyPrice: (defaultPropertyPrice) =>
      set(() => ({
        defaultPropertyPrice,
      })),
    updateVillaPricePerNight: (villaPricePerNight) =>
      set(() => ({
        villaPricePerNight,
      })),
  };
});
