export const ReferralGiftIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3843_20291"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3843_20291)">
        <path
          d="M3.33268 18.333V9.16634H1.66602V4.16634H5.99935C5.9299 4.04134 5.88477 3.9094 5.86393 3.77051C5.8431 3.63162 5.83268 3.48579 5.83268 3.33301C5.83268 2.63856 6.07574 2.04829 6.56185 1.56217C7.04796 1.07606 7.63824 0.833008 8.33268 0.833008C8.65213 0.833008 8.95074 0.892036 9.22852 1.01009C9.50629 1.12815 9.76324 1.29134 9.99935 1.49967C10.2355 1.27745 10.4924 1.11079 10.7702 0.999674C11.048 0.888563 11.3466 0.833008 11.666 0.833008C12.3605 0.833008 12.9507 1.07606 13.4368 1.56217C13.923 2.04829 14.166 2.63856 14.166 3.33301C14.166 3.48579 14.1521 3.62815 14.1243 3.76009C14.0966 3.89204 14.0549 4.02745 13.9993 4.16634H18.3327V9.16634H16.666V18.333H3.33268ZM11.666 2.49967C11.4299 2.49967 11.232 2.57954 11.0723 2.73926C10.9125 2.89898 10.8327 3.0969 10.8327 3.33301C10.8327 3.56912 10.9125 3.76704 11.0723 3.92676C11.232 4.08648 11.4299 4.16634 11.666 4.16634C11.9021 4.16634 12.1 4.08648 12.2598 3.92676C12.4195 3.76704 12.4993 3.56912 12.4993 3.33301C12.4993 3.0969 12.4195 2.89898 12.2598 2.73926C12.1 2.57954 11.9021 2.49967 11.666 2.49967ZM7.49935 3.33301C7.49935 3.56912 7.57921 3.76704 7.73893 3.92676C7.89865 4.08648 8.09657 4.16634 8.33268 4.16634C8.56879 4.16634 8.76671 4.08648 8.92643 3.92676C9.08616 3.76704 9.16602 3.56912 9.16602 3.33301C9.16602 3.0969 9.08616 2.89898 8.92643 2.73926C8.76671 2.57954 8.56879 2.49967 8.33268 2.49967C8.09657 2.49967 7.89865 2.57954 7.73893 2.73926C7.57921 2.89898 7.49935 3.0969 7.49935 3.33301ZM3.33268 5.83301V7.49967H9.16602V5.83301H3.33268ZM9.16602 16.6663V9.16634H4.99935V16.6663H9.16602ZM10.8327 16.6663H14.9993V9.16634H10.8327V16.6663ZM16.666 7.49967V5.83301H10.8327V7.49967H16.666Z"
          fill="#33312F"
        />
      </g>
    </svg>
  );
};
